//import store from '@/state/store'

export default [

    // My COMPONENTS STARTS HERE //

    {
        path: '/',
        name: 'My Login',
        component: () =>
            import ('@/new-components/login/Login.vue'),
    },

    {
        path: '/pages/forgetpassword',
        name: 'Forget Password',
        component: () =>
            import ('@/new-components/forgot-password/ForgotPassword.vue'),
    },

    {
        path: '/signup',
        name: 'Sign up',
        component: () =>
            import ('@/new-components/signup/SignUp.vue'),
    },
    {
        path: '/pages/add-listings',
        name: 'Add Listings',
        component: () =>
            import ('@/new-views/add-listings/AddListings.vue'),
    },
    {
        path: '/pages/add-tenants',
        name: 'Add Tenants',
        component: () =>
            import ('@/new-views/add-tenants/AddTenants.vue'),
    },
    {
        path: '/pages/dashboard',
        name: 'Dashboard',
        component: () =>
            import ('@/new-views/home-items/HomeScreenOne.vue'),
    },
    {
        path: '/pages/listings',
        name: 'Listings',
        component: () =>
            import ('@/new-views/home-items/HomeScreenTwo.vue'),
    },
    {
        path: '/pages/profile',
        name: 'Profile',
        component: () =>
            import ('@/new-components/Profile/NewProfile.vue'),
    },
    {
        path: '/pages/tenants',
        name: 'Tenants',
        component: () =>
            import ('@/new-views/home-items/HomeScreenThree.vue'),
    },
    {
        path: '/pages/units',
        name: 'Units',
        component: () =>
            import ('@/new-views/home-items/HomeScreenFour.vue'),
    },
    {
        path: '/pages/financials',
        name: 'Financials',
        component: () =>
            import ('@/new-views/home-items/HomeScreenFive.vue'),
    },

    {
        path: '/pages/edit-tenant/:id',
        name: 'Edit Tenants',
        component: () =>
            import ('@/new-views/add-tenants/AddTenants.vue'),
    },

    {
        path: '/pages/edit-listing/:id',
        name: 'Edit Listings',
        component: () =>
            import ('@/new-views/add-listings/AddListings.vue'),
    },


    // My COMPONENTS ENDS HERE //


    {
        path: '/calendar',
        name: 'calendar',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/calendar/index')
    },
    {
        path: '/chat',
        name: 'chat',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/chat/index')
    },
    {
        path: '/ecommerce/products',
        name: 'Products',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/ecommerce/products')
    },
    {
        path: '/ecommerce/product-detail/:id',
        name: 'Product Detail',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/ecommerce/product-detail')
    },
    {
        path: '/ecommerce/orders',
        name: 'Orders',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/ecommerce/orders')
    },
    {
        path: '/ecommerce/customers',
        name: 'Customers',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/ecommerce/customers')
    },
    {
        path: '/ecommerce/cart',
        name: 'Cart',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/ecommerce/cart')
    },
    {
        path: '/ecommerce/checkout',
        name: 'Checkout',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/ecommerce/checkout')
    },
    {
        path: '/ecommerce/shops',
        name: 'Shops',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/ecommerce/shops')
    },
    {
        path: '/ecommerce/add-product',
        name: 'Add Product',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/ecommerce/add-product')
    },
    {
        path: '/email/inbox',
        name: 'Inbox',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/email/inbox')
    },
    {
        path: '/email/reademail/:id',
        name: 'Read Email',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/email/reademail')
    },
    {
        path: '/invoices/detail',
        name: 'Invoice Detail',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/invoices/detail')
    },
    {
        path: '/invoices/list',
        name: 'Invoice List',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/invoices/list')
    },
    {
        path: '/contacts/grid',
        name: 'User Grid',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/contacts/grid')
    },
    {
        path: '/contacts/list',
        name: 'User List',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/contacts/list')
    },
    {
        path: '/contacts/profile',
        name: 'Profile',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/contacts/profile')
    },
    {
        path: '/utility/starter',
        name: 'Starter-page',
        meta: {
            authRequired: false
        },
        component: () =>
            import ('../views/pages/utility/starter')
    },
    {
        path: '/utility/maintenance',
        name: 'maintenance',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/utility/maintenance')
    },
    {
        path: '/utility/comingsoon',
        name: 'comingsoon',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/utility/comingsoon')
    },
    {
        path: '/utility/timeline',
        name: 'timeline',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/utility/timeline')
    },
    {
        path: '/utility/faqs',
        name: 'faqs',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/utility/faqs')
    },
    {
        path: '/utility/pricing',
        name: 'pricing',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/utility/pricing')
    },
    {
        path: '/utility/404',
        name: 'error-404',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/utility/404')
    },
    {
        path: '/utility/500',
        name: 'error-500',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/utility/500')
    },
    {
        path: '/form/elements',
        name: 'elements',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/form/elements')
    },
    {
        path: '/form/validation',
        name: 'validation',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/form/validation')
    },
    {
        path: '/form/advanced',
        name: 'form-advanced',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/form/advanced')
    },
    {
        path: '/form/editor',
        name: 'editor',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/form/editor')
    },
    {
        path: '/form/upload',
        name: 'upload',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/form/upload')
    },
    {
        path: '/form/repeater',
        name: 'repeater',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/form/repeater')
    },
    {
        path: '/form/wizard',
        name: 'wizard',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/form/wizard')
    },
    {
        path: '/form/mask',
        name: 'mask',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/form/mask')
    },
    {
        path: '/tables/basic',
        name: 'basic-table',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/tables/basic')
    },
    {
        path: '/tables/advanced',
        name: 'advanced',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/tables/advanced')
    },
    {
        path: '/charts/apex',
        name: 'apex',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/charts/apex/index')
    },
    {
        path: '/charts/chartist',
        name: 'chartist',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/charts/chartist/index')
    },
    {
        path: '/charts/chartjs',
        name: 'chartjs',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/charts/chartjs/index')
    },
    {
        path: '/charts/echart',
        name: 'echart',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/charts/echart/index')
    },
    {
        path: '/icons/unicons',
        name: 'unicons',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/icons/unicons')
    },
    {
        path: '/icons/boxicons',
        name: 'boxicons',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/icons/boxicons')
    },
    {
        path: '/icons/materialdesign',
        name: 'materialdesign',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/icons/materialdesign')
    },
    {
        path: '/icons/dripicons',
        name: 'dripicons',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/icons/dripicons')
    },
    {
        path: '/icons/fontawesome',
        name: 'fontawesome',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/icons/fontawesome')
    },
    {
        path: '/maps/google',
        name: 'google',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/maps/google')
    },
    {
        path: '/maps/leaflet',
        name: 'leaflet',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/maps/leaflet')
    },
    {
        path: '/ui/alerts',
        name: 'alerts',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/alerts')
    },
    {
        path: '/ui/buttons',
        name: 'buttons',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/buttons')
    },
    {
        path: '/ui/cards',
        name: 'cards',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/cards')
    }, {
        path: '/ui/carousel',
        name: 'carousel',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/carousel')
    }, {
        path: '/ui/dropdown',
        name: 'dropdown',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/dropdown')
    }, {
        path: '/ui/grid',
        name: 'grid',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/grid')
    }, {
        path: '/ui/images',
        name: 'images',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/images')
    }, {
        path: '/ui/lightbox',
        name: 'lightbox',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/lightbox')
    },
    {
        path: '/ui/modals',
        name: 'modals',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/modals')
    },
    {
        path: '/ui/rangeslider',
        name: 'rangeslider',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/rangeslider')
    },
    {
        path: '/ui/progressbar',
        name: 'progressbar',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/progressbar')
    },
    {
        path: '/ui/sweet-alert',
        name: 'sweet-alert',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/sweet-alert')
    },

    {
        path: '/ui/tabs-accordions',
        name: 'tabs-accordions',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/tabs-accordions')
    },
    {
        path: '/ui/typography',
        name: 'typography',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/typography')
    },

    {
        path: '/ui/video',
        name: 'video',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/video')
    },
    {
        path: '/ui/general',
        name: 'general',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/general')
    },
    {
        path: '/ui/colors',
        name: 'colors',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/colors')
    },
    {
        path: '/ui/rating',
        name: 'rating',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/ui/rating')
    },
    {
        path: '/auth/login-1',
        name: 'login-1',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/auth/login-1')
    },
    {
        path: '/auth/register-1',
        name: 'register-1',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/auth/register-1')
    }, {
        path: '/auth/lock-screen',
        name: 'lock-screen',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/auth/lock-screen')
    }, {
        path: '/auth/recoverpwd',
        name: 'recoverpwd',
        meta: {
            authRequired: false,
        },
        component: () =>
            import ('../views/pages/auth/recoverpwd')
    },

]